var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex row m-0 justify-space-between align-center"},[_c('AdminPageButtons',{on:{"change":function($event){return _vm.$emit('change', _vm.page)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('UserDetailsForm',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('Button',{staticClass:"admin-addition-button",attrs:{"image":_vm.plus,"text":_vm.$t('admin.user-settings.add.title')}})]},proxy:true}])})],1),_c('Table',{staticClass:"px-5 py-3",attrs:{"data":_vm.usersPageable,"fields":_vm.tableFields,"formattedColumns":['avatar', 'lastName', 'email', 'roles', 'categories', 'external', 'lastLogin', 'buttons'],"isLoading":_vm.loading || _vm.filtering,"stickyHeader":"52.5rem"},on:{"newPageOption":_vm.pageOptionsChanged},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var rowData = ref.rowData;
return [_c('UserAvatar',{staticClass:"d-flex justify-center",attrs:{"user":rowData,"isAdmin":""}})]}},{key:"lastName",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.fullName(rowData))+" ")]}},{key:"email",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.email)+" ")]}},{key:"roles",fn:function(ref){
var rowData = ref.rowData;
return [_c('Roles',{attrs:{"userId":rowData.id,"roles":rowData.roles,"isAdmin":""}})]}},{key:"categories",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isEmployee(rowData))?_c('div',_vm._l((rowData.workfields),function(category){return _c('p',{key:category,staticClass:"m-0"},[(_vm.isNotOtherCategory(category))?_c('span',[_vm._v(_vm._s(_vm.$t('category.' + category.toLowerCase())))]):_vm._e()])}),0):_vm._e()]}},{key:"external",fn:function(ref){
var rowData = ref.rowData;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!rowData.external},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({class:{ 'info-container': rowData.external }},on),[_vm._v(" "+_vm._s(rowData.external ? 'fas fa-check' : 'fas fa-times')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(rowData.transporterBusiness))])])]}},{key:"lastLogin",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(rowData.lastLogin ? _vm.formatDate(rowData.lastLogin) : _vm.$t('admin.user-settings.not-signed-in-yet'))+" ")])]}},{key:"buttons",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex row justify-space-around m-0"},[_c('UserDetailsForm',{attrs:{"isAddition":false,"user":rowData},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('EditButton',{attrs:{"iconType":"edit","isLarge":""}})]},proxy:true}],null,true)}),_c('DeleteUser',{attrs:{"user":rowData},on:{"deleteFinished":_vm.fetch}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }