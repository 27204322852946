





































































































import Vue from 'vue';
import { required, email, requiredIf, maxLength } from 'vuelidate/lib/validators';
import FormBase from '@/components/Form/Form.vue';
import CheckBox from '@/components/InputField/Checkbox.vue';
import Roles from '@/components/Roles.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Select from '@/components/InputField/Select.vue';
import UserDetails from '@/model/user-details';
import { Role } from '@/model/enums/role';
import UserAdminRequest from '@/model/request/user-admin-request';
import { Category } from '@/model/enums/category';
import { categories } from '@/constants/categories';
import { mapGetters } from 'vuex';
import Business from '@/model/business';
import { guestRole, categoryList } from '@/validators/admin';
import { notEmptyList } from '@/validators/common';

export default Vue.extend({
  name: 'UserDetailsForm',
  components: {
    FormBase,
    CheckBox,
    Roles,
    LoadingIndicator,
    Select,
  },
  props: {
    user: Object as () => UserDetails,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      businessLoading: true,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        roles: [] as Role[],
        external: false,
        transporterBusiness: '',
        workfields: [] as Category[],
        business: {} as Business,
      },
      categorieList: categories,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        maxLength: maxLength(75),
      },
      lastName: {
        required,
        maxLength: maxLength(75),
      },
      email: {
        required,
        email,
        maxLength: maxLength(75),
      },
      roles: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.roles);
        },
        guestRole(this: any) {
          return guestRole(this.form.roles);
        },
      },
      workfields: {
        categoryList(this: any) {
          return categoryList(this.isEmployee, this.form.workfields);
        },
      },
      transporterBusiness: {
        required: requiredIf(function(this: any) {
          return this.form.external;
        }),
        maxLength: maxLength(150),
      },
    },
  },
  computed: {
    ...mapGetters({ businesses: 'businessStorage/getBusinesses', isAdmin: 'currentUserStorage/isAdmin' }),
    businessOptions(): Business[] {
      const options = this.businesses;
      if (this.user?.business && !options.find((item: Business) => item.id === this.user?.business?.id)) {
        options.unshift(this.user.business);
      }
      return options;
    },
    lastNameErrors(): string {
      return this.submitted && !(this.$v.form.lastName as any).required
        ? this.$t('admin.user-settings.modify.last-name-required').toString()
        : this.submitted && !(this.$v.form.lastName as any).maxLength
        ? this.$t('admin.user-settings.modify.last-name-max-length').toString()
        : '';
    },
    firstNameErrors(): string {
      return this.submitted && !(this.$v.form.firstName as any).required
        ? this.$t('admin.user-settings.modify.first-name-required').toString()
        : this.submitted && !(this.$v.form.firstName as any).maxLength
        ? this.$t('admin.user-settings.modify.first-name-max-length').toString()
        : '';
    },
    emailError(): string {
      return this.submitted && !(this.$v.form.email as any).required
        ? this.$t('admin.user-settings.modify.email-required').toString()
        : this.submitted && !(this.$v.form.email as any).email
        ? this.$t('admin.user-settings.modify.email-incorrect-format').toString()
        : this.submitted && !(this.$v.form.email as any).maxLength
        ? this.$t('admin.user-settings.modify.email-max-length').toString()
        : '';
    },
    userRoleError(): string {
      return this.submitted && !(this.$v.form.roles as any).notEmptyList
        ? this.$t('admin.user-settings.modify.one-role-required').toString()
        : this.submitted && !(this.$v.form.roles as any).guestRole
        ? this.$t('admin.user-settings.modify.guest-only-role').toString()
        : '';
    },
    userCategoryError(): string {
      return this.submitted && !(this.$v.form.workfields as any).categoryList
        ? this.$t('admin.user-settings.modify.one-category-required').toString()
        : '';
    },
    transporterBusinessError(): string {
      return this.submitted && !(this.$v.form.transporterBusiness as any).required
        ? this.$t('admin.user-settings.modify.transporter-business-required').toString()
        : this.submitted && !(this.$v.form.transporterBusiness as any).maxLength
        ? this.$t('admin.user-settings.modify.transporter-business-max-length').toString()
        : '';
    },
    isEmployee(): boolean {
      return this.form.roles.includes(Role.EMPLOYEE);
    },
  },
  watch: {
    isAdmin() {
      if (!this.isAdmin) {
        this.$router.push('/');
      }
    },
  },
  methods: {
    setFormFields() {
      this.form.firstName = this.user.firstName;
      this.form.lastName = this.user.lastName;
      this.form.email = this.user.email;
      this.form.roles = [...this.user.roles];
      this.form.external = this.user.external;
      this.form.transporterBusiness = this.user.transporterBusiness;
      this.form.workfields = this.user.workfields ? [...this.user.workfields] : [];
      this.form.business = this.user.business;
    },
    resetForm() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.email = '';
      this.form.roles = [] as Role[];
      this.form.external = false;
      this.form.transporterBusiness = '';
      this.form.workfields = [] as Category[];
      this.form.business = {} as Business;
      this.submitted = false;
    },
    openDialog() {
      if (this.user) {
        this.setFormFields();
      }
      this.$store.dispatch('businessStorage/getBusinesses').finally(() => (this.businessLoading = false));
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch(
          'adminUserStorage/save',
          new UserAdminRequest(
            this.user ? this.user.id : null,
            this.form.email,
            this.form.firstName,
            this.form.lastName,
            this.form.roles,
            this.form.external,
            this.form.transporterBusiness,
            this.form.workfields,
            this.form.business ? this.form.business.id : null,
          ),
        )
        .then((data) => {
          if (data) {
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
    onRoleModification(modified: Role) {
      (this as any).addOrRemoveItem(this.form.roles, modified);
    },
  },
});
