import { Category } from '@/model/enums/category';
import { Role } from '@/model/enums/role';
import { notEmptyList } from '@/validators/common';

export const guestRole = (list: Role[]) => {
  return list.includes(Role.GUEST) ? list.length === 1 : notEmptyList(list);
};

export const categoryList = (isEmplyee: boolean, list: Category[]) => {
  return isEmplyee ? notEmptyList(list) : true;
};
