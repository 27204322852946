



































































import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';
import Table from '@/components/Table/Table.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import Roles from '@/components/Roles.vue';
import UserDetailsForm from '@/components/Admin/Users/UserDetailsForm.vue';
import EditButton from '@/components/Button/CircleButton.vue';
import DeleteUser from '@/components/Admin/Users/DeleteUser.vue';
import Button from '@/components/Button/Button.vue';
import AdminPageButtons from '@/components/Admin/PageButtons.vue';
import { Role } from '@/model/enums/role';
import UserDetails from '@/model/user-details';
import Menu from '@/model/menu';
import { Category } from '@/model/enums/category';

export default Vue.extend({
  name: 'AdminUsers',
  components: {
    LoadingIndicator,
    Table,
    UserAvatar,
    Roles,
    UserDetailsForm,
    EditButton,
    DeleteUser,
    Button,
    AdminPageButtons,
  },
  props: {
    selectedPage: { type: Object as () => Menu },
  },
  watch: {
    selectedPage() {
      this.page = this.selectedPage;
    },
  },
  data() {
    return {
      loading: true,
      pageOptions: new PageOptions(0, 25),
      page: this.selectedPage,
      plus: '/images/plus.svg',
      tableFields: [
        {
          key: 'avatar',
          label: '',
          thStyle: 'width: 5%',
        },
        {
          text: this.$t('admin.user-settings.header.name'),
          key: 'lastName',
          label: this.$t('admin.user-settings.header.name'),
          sortable: true,
          thStyle: 'width: 20%',
        },
        {
          text: this.$t('admin.user-settings.header.email'),
          key: 'email',
          label: this.$t('admin.user-settings.header.email'),
          sortable: true,
          thStyle: 'width: 20%',
        },
        {
          text: this.$t('admin.user-settings.header.roles'),
          key: 'roles',
          label: this.$t('admin.user-settings.header.roles'),
          thStyle: 'width: 15%',
        },
        {
          text: this.$t('admin.user-settings.header.categories'),
          key: 'categories',
          label: this.$t('admin.user-settings.header.categories'),
          thStyle: 'width: 15%',
        },
        {
          text: this.$t('admin.user-settings.header.external'),
          key: 'external',
          label: this.$t('admin.user-settings.header.external'),
          sortable: true,
          thStyle: 'width: 5%',
        },
        {
          text: this.$t('admin.user-settings.header.last-login'),
          key: 'lastLogin',
          label: this.$t('admin.user-settings.header.last-login'),
          sortable: true,
          thStyle: 'width: 10%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 10%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      usersPageable: 'adminUserStorage/getUsers',
      filtering: 'filterStorage/isFiltering',
      searchText: 'filterStorage/getAdminUsersSearchText',
    }),
  },
  mounted() {
    this.fetch();
    this.$emit('setFilter', this.searchText);
  },
  methods: {
    fetch(): void {
      this.loading = true;
      this.$store.dispatch('adminUserStorage/getUsersPage', this.pageOptions).finally(() => (this.loading = false));
    },
    pageOptionsChanged(newPageOptions: PageOptions): void {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
    isEmployee(user: UserDetails): boolean {
      return user.roles.includes(Role.EMPLOYEE);
    },
    isNotOtherCategory(category: Category) {
      return category !== Category.OTHER;
    },
    textFiltering(text: string) {
      this.$store.commit('filterStorage/setAdminUsersSearchText', text);
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store
        .dispatch('adminUserStorage/getUsersPage', { pageOptions: new PageOptions() })
        .finally(() => this.$store.commit('filterStorage/setFiltering', false));
    },
  },
});
