import { Role } from '@/model/enums/role';
import { Category } from '@/model/enums/category';

export default class UserAdminRequest {
  public id: number | null;
  public email: string;
  public firstName: string;
  public lastName: string;
  public roles: Role[];
  public external: boolean;
  public transporterBusiness: string;
  public workfields: Category[];
  public businessId: number | null;

  constructor(
    id: number | null,
    email: string,
    firstName: string,
    lastName: string,
    roles: Role[],
    external: boolean,
    transporterBusiness: string,
    workfields: Category[],
    businessId: number | null,
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.roles = roles;
    this.external = external;
    this.transporterBusiness = transporterBusiness;
    this.workfields = workfields;
    this.businessId = businessId;
  }
}
