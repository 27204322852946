




import Vue from 'vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import UserDetails from '@/model/user-details';

export default Vue.extend({
  name: 'DeleteUser',
  components: {
    DeleteButton,
  },
  props: {
    user: Object as () => UserDetails,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteUser() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('admin.user-settings.delete.confirmation', {
              name: `${(this as any).fullName(this.user)}`,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store.dispatch('adminUserStorage/delete', this.user.id).finally(() => {
              this.loading = false;
              this.$emit('deleteFinished');
            });
          }
        });
    },
  },
});
