import { i18n } from '@/i18n';
import { Category } from '@/model/enums/category';

export const categories = [
  {
    type: Category.LABOUR,
    text: i18n.t('category.labour'),
  },
  {
    type: Category.FIRE,
    text: i18n.t('category.fire'),
  },
  {
    type: Category.ENVIRONMENT,
    text: i18n.t('category.environment'),
  },
];
